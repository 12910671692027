import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UnderConstructionPage from "../components/underConstruction"

const WhyUs = () => (
  <Layout>
    <SEO title="Why Us" />
    <UnderConstructionPage></UnderConstructionPage>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default WhyUs
